<template>
  <UidDesktop v-if="!isMobile()" />
  <UidMobile v-else />
</template>

<script>
import { isMobile } from "@/libs/helpers";

export default {
  name: "Uid",
  components: {
    UidDesktop: () => import("@/components/goods/Uid"),
    UidMobile: () => import("@/components/goods/UidMobile"),
  },
  methods: {
    isMobile,
  },
};
</script>

<style scoped></style>
